import {
	Button,
	List,
	ListItem,
	ListItemText,
	Paper,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Chat } from '../../lib/api/moon/Chat';
import useAuth from '../../hooks/Wallet/useAuth';
import { ApiConfig, HttpClient } from '../../lib/api/moon/http-client';

interface Message {
	text: string;
	sender: string;
}

const ChatPage: React.FC = () => {
	const [input, setInput] = useState('');
	const [messages, setMessages] = useState<Message[]>([]);
	const { isAuthenticated, token } = useAuth();
	const [chat, setChat] = useState<Chat>(
		new Chat({
			baseUrl: 'https://vault-api.usemoon.ai',
			securityWorker: async (token) => {
				return {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
			},
		})
	);

	chat.setSecurityData(token);
	useEffect(() => {
		chat.setSecurityData(token);
		console.log(token);
	}, [isAuthenticated, token, chat]);

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInput(e.target.value);
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (input.trim() !== '') {
			const userMessage: Message = { text: input, sender: 'user' };
			setMessages([...messages, userMessage]);
			console.log(messages);

			// Call your chat bot API here and add the bot's response to the messages state
			// const botMessage: Message = { text: 'uwu', sender: 'bot' };
			// setMessages([...messages, [userMessage, botMessage]]);

			// // Call your chat bot API here and add the response to the messages state
			// setInput('');

			const res = await chat.chatMoon(
				{
					query: input,
				},
				{
					secure: true,
				}
			);

			setInput('');
			// console.log(res.data.result.output);
			// const output = JSON.stringify(res.data.results.output, null, 2);
			const output = res.data.results?.output || 'No output';
			setMessages([...messages, { text: output, sender: 'bot' }]);
		}
	};

	return (
		<Paper style={{ maxWidth: 600, margin: 'auto', marginTop: 50 }}>
			<List>
				{messages.map((message, index) => (
					<ListItem key={index} alignItems="flex-start">
						<ListItemText primary={message.text} secondary={message.sender} />
					</ListItem>
				))}
			</List>
			<form onSubmit={handleSubmit} style={{ display: 'flex' }}>
				<TextField
					label="Type a message"
					variant="outlined"
					value={input}
					onChange={handleInput}
					style={{ flex: 1 }}
				/>
				<Button type="submit" variant="contained" color="primary">
					Send
				</Button>
			</form>
		</Paper>
	);
};

export default ChatPage;
