import {
	startAuthentication,
	startRegistration,
} from '@simplewebauthn/browser';
import { Provider, ethers } from 'ethers';
import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Accounts } from '../../lib/api/moon/Accounts';
import { AccountResponse } from '../../lib/api/moon/data-contracts';
import { ApiConfig } from '../../lib/api/moon/http-client';

interface AuthState {
	user: User | null;
	isAuthenticated: boolean;
	loading: boolean;
	token: string | null;
	refreshToken: string | null;
	setIsAuthenticated: (isAuthenticated: boolean) => void;
	setToken: (token: string) => void;
	login: (email: string, password: string) => Promise<void>;
	logout: () => Promise<void>;
	register: (email: string, password: string) => Promise<void>;
	refresh: () => Promise<void>;
	forgotPassword: (email: string) => Promise<void>;
	resetPassword: (password: string, token: string) => Promise<void>;
	verifyEmail: (token: string) => Promise<void>;
	updateProfile: (data: any) => Promise<void>;
	updatePassword: (password: string, newPassword: string) => Promise<void>;
	updateEmail: (email: string, password: string) => Promise<void>;
	deleteAccount: (password: string) => Promise<void>;
	getTwoFactorAuthenticationCode: () => Promise<void>;
	handleGoogleLogin: () => Promise<void>;
	handleGithubLogin: () => Promise<void>;
	handleFacebookLogin: () => Promise<void>;
	handleTwitterLogin: () => Promise<void>;
	handleLogin: (username: string) => Promise<void>;
	handleRegister: (username: string) => Promise<void>;
	handleDiscordLogin: () => Promise<void>;

	wallets: Wallet[];
	selectedWallet: Wallet | null;
	chain: Chain | null;
	provider: Provider | null;
	signer: ethers.Signer | null;
	nonce: string;
	setNonce: (nonce: string) => void;

	setWallets: (wallets: Wallet[]) => void;
	setSelectedWallet: (wallet: Wallet) => void;
	setChain: (chain: Chain) => void;
	setProvider: (provider: Provider) => void;
	setSigner: (signer: ethers.Signer) => void;

	createWallet: (privateKey: string) => Promise<void>;
	fetchWallets: () => Promise<void>;
	deployContract: (
		wallet: Wallet,
		abi: any,
		bytecode: any,
		constructor_args: any,
		chainId: string
	) => Promise<void>;
}
interface User {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	displayName: string;
	photoURL: string;
}

const AuthContext = createContext<AuthState>({
	user: null,
	isAuthenticated: false,
	loading: false,
	token: null,
	refreshToken: null,
	setIsAuthenticated: () => {},
	setToken: () => {},

	login: async () => {},
	logout: async () => {},
	register: async () => {},
	refresh: async () => {},
	forgotPassword: async () => {},
	resetPassword: async () => {},
	verifyEmail: async () => {},
	updateProfile: async () => {},
	updatePassword: async () => {},
	updateEmail: async () => {},
	deleteAccount: async () => {},
	getTwoFactorAuthenticationCode: async () => {},
	handleGoogleLogin: async () => {},
	handleGithubLogin: async () => {},
	handleFacebookLogin: async () => {},
	handleTwitterLogin: async () => {},
	handleLogin: async () => {},
	handleRegister: async () => {},
	handleDiscordLogin: async () => {},

	wallets: [],
	selectedWallet: null,
	chain: null,
	provider: null,
	signer: null,
	nonce: '0',
	setNonce: () => {},

	setWallets: () => {},
	setSelectedWallet: () => {},
	setChain: () => {},
	setProvider: () => {},
	setSigner: () => {},

	fetchWallets: async () => {},
	createWallet: async () => {},
	deployContract: async () => {},
});

export const useAuth = () => useContext(AuthContext);

export interface Wallet {
	id: string;
	name: string;
	balance: number;
	nonce: string;
}

export interface Chain {
	id: string;
	name: string;
	symbol: string;
	decimals: number;
	rpc: string;
}

export const chains: Chain[] = [
	{
		id: '1',
		name: 'Ethereum',
		symbol: 'ETH',
		decimals: 18,
		rpc: 'https://mainnet.infura.io/v3/3d8f3b0b9a5b4b6e8b0b3b0b3b0b3b0b',
	},
	{
		id: '1337',
		name: 'Moon',
		symbol: 'ETH',
		decimals: 18,
		rpc: 'https://grpc.moon.social',
	},
];

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [user, setUser] = useState<User | null>(null);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [token, setToken] = useState<string | null>(null);
	const [refreshToken, setRefreshToken] = useState<string | null>(null);

	const [wallets, setWallets] = useState<Wallet[]>([]);
	const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
	const [chain, setChain] = useState<Chain | null>(chains[0]);
	const [provider, setProvider] = useState<Provider | null>(null);
	const [signer, setSigner] = useState<ethers.Signer | null>(null);
	const [contract, setContract] = useState<string>('');
	const [nonce, setNonce] = useState<string>('0');
	// const loginWithWorldID = async (username: string) => {

	const login = async (email: string, password: string) => {
		try {
			const response = await fetch('/api/login', {
				method: 'POST',
				body: JSON.stringify({ email, password }),
			}).then((res) => res.json());
			setUser(response.data);
		} catch (error) {
			setUser(null);
		}
	};

	const logout = async () => {
		// Send a logout request to the server
		const response = await fetch('https://vault-api.usemoon.ai/auth/logout', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.ok) {
			// Clear the authentication token and mark the user as unauthenticated
			setToken(null);
			setIsAuthenticated(false);
			// Clear the authentication token cookie
			document.cookie =
				'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		} else {
			throw new Error('Logout failed');
		}
	};
	const handleGoogleLogin = async () => {
		try {
			setLoading(true);
			// const res = await fetch('https://vault-api.usemoon.ai/auth/oauth/google');
			// const { url } = await res.json();
			window.location.href =
				'https://vault-api.usemoon.ai/auth/oauth/google-alt';
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const handleGithubLogin = async () => {
		try {
			setLoading(true);
			const res = await fetch('https://vault-api.usemoon.ai/auth/oauth/github');
			const { url } = await res.json();
			window.location.href = url;
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};
	const handleLogin = async (username: string) => {
		try {
			const publicKey = await fetch(
				'http://localhost:3000/auth/webauthn/login',
				{
					method: 'POST',
					body: JSON.stringify({ username }),
					headers: { 'Content-Type': 'application/json' },
				}
			).then((res) => res.json());

			const credential = await startAuthentication(publicKey.optionsAuth);

			const verified = await fetch(
				'http://localhost:3000/auth/webauthn/login/verify',
				{
					method: 'POST',
					body: JSON.stringify({
						...credential,
						username: username,
					}),
					headers: { 'Content-Type': 'application/json' },
				}
			).then((res) => res.json());
			console.log(verified);
			setToken(verified.token);
			//set cookie for jwt token
			document.cookie = `authToken=${verified.token}`;
			setIsAuthenticated(true);

			alert('Successfully logged in with WebAuthn!');
		} catch (err) {
			console.error(err);
			alert('Failed to log in with WebAuthn.');
		}
	};

	const handleRegister = async (username: string) => {
		try {
			const publicKey = await fetch(
				'http://localhost:3000/auth/webauthn/register',
				{
					method: 'POST',
					body: JSON.stringify({ username }),
					headers: { 'Content-Type': 'application/json' },
				}
			).then((res) => res.json());
			const credential = await startRegistration(publicKey.options);
			await fetch('http://localhost:3000/auth/webauthn/register/verify', {
				method: 'POST',
				body: JSON.stringify({
					...credential,
					username: username,
					user: {
						...publicKey.options.user,
					},
				}),
				headers: { 'Content-Type': 'application/json' },
			});
			alert('Successfully registered with WebAuthn!');
		} catch (err) {
			console.error(err);
			alert('Failed to register with WebAuthn.');
		}
	};

	const handleTwitterLogin = async () => {
		try {
			setLoading(true);
			const res = await fetch(
				'https://vault-api.usemoon.ai/auth/oauth/twitter'
			);
			const { url } = await res.json();
			window.location.href = url;
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const handleUsernamePasswordLogin = async () => {
		try {
			setLoading(true);
			// Handle username/password login
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const refresh = async () => {};

	const forgotPassword = async (email: string) => {};

	const resetPassword = async (password: string, token: string) => {};

	const verifyEmail = async (token: string) => {};

	const updateProfile = async (data: any) => {};

	const updatePassword = async (password: string, newPassword: string) => {};

	const updateEmail = async (email: string, password: string) => {};

	const deleteAccount = async (password: string) => {};

	const getTwoFactorAuthenticationCode = async () => {};
	const register = async (email: string, password: string) => {};

	const handleFacebookLogin = async () => {};

	const handleDiscordLogin = async () => {
		try {
			setLoading(true);
			// const res = await fetch('https://vault-api.usemoon.ai/auth/oauth/google');
			// const { url } = await res.json();
			window.location.href = 'http://localhost:3000/auth/oauth/discord';
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	// useEffect(() => {
	// 	const initEthers = async () => {
	// 		// const provider = ethers.providers.getDefaultProvider('rinkeby');
	// 		// const signer = new ethers.Wallet('YOUR_PRIVATE_KEY', provider);
	// 		const provider = new ethers.JsonRpcProvider(
	// 			chain?.rpc || 'https://grpc.moon.social'
	// 		);
	// 		const signer = new MoonSigner(
	// 			selectedWallet?.name || '',
	// 			'https://vault-api.usemoon.ai',
	// 			provider
	// 		);
	// 		const moonSigner = signer.connect(provider);
	// 		// const provider = ethers.providers.getDefaultProvider('rinkeby');
	// 		// azureKeyVaultSigner = azureKeyVaultSigner.connect(provider);
	// 		setProvider(provider);
	// 		setSigner(signer);
	// 	};
	// 	initEthers();
	// }, [selectedWallet, chain]);

	// Fetch the list of wallets from your API
	const fetchBalance = async (key: string) => {
		const api = new Accounts({
			baseUrl: 'https://vault-api.usemoon.ai',
			securityWorker: async (token: string) => {
				return {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
			},
		} as ApiConfig);
		api.setSecurityData(token);
		const balance: AccountResponse = await api
			.getBalance(key, {
				secure: true,
			})
			.then((res) => res.data);
		// set balance for wallet and update wallets
		// const newWallets: Wallet[] = [
		// 	...wallets,
		// 	{
		// 		id: key,
		// 		name: key,
		// 	},
		// ];
		// return newWallets;
		return Number(balance.data?.balance) || 0;
	};
	// const transferEther = async (
	//     wallet: Wallet,
	//     to: string,
	//     amount: number,
	//     gasPrice: number,
	//     gasLimit: number
	//     chainId: string
	// ) => {
	//     const api = new Accounts({
	//         baseUrl: 'https://vault-api.usemoon.ai',
	//         securityWorker: async (token: string) => {
	//             return {
	//                 headers: {
	//                     Authorization: `Bearer ${token}`,
	//                 },
	//             };
	//         },
	//     } as ApiConfig);
	//     api.setSecurityData(token);
	//     const transfer: AccountResponse = await api
	//         .transferEth(
	//             wallet.name,
	//             {
	//                 to: to || '',
	//                 value: amount || '',
	//                 chainId: chainId || '',
	//             },
	//             {
	//                 secure: true,
	//             }
	//         )
	//         .then((res) => res.data);
	//     // return contract.data?.address || '';
	//     setContract(transfer.data?.address || '');
	// };
	// }
	const deployContract = async (
		wallet: Wallet,
		abi: any,
		bytecode: any,
		constructor_args: any
	) => {
		const api = new Accounts({
			baseUrl: 'https://vault-api.usemoon.ai',
			securityWorker: async (token: string) => {
				return {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
			},
		} as ApiConfig);
		api.setSecurityData(token);
		const contract: AccountResponse = await api
			.deployContract(
				wallet.name,
				{
					abi: abi || '',
					bytecode: bytecode || '',
					constructor_args: constructor_args || '',
					chainId: '1337',
					// chainId: chain?.id || '1337',
				},
				{
					secure: true,
				}
			)
			.then((res) => res.data);
		// return contract.data?.address || '';
		setContract(contract.data?.address || '');
	};

	const getNonce = async (wallet: Wallet) => {
		const api = new Accounts({
			baseUrl: 'https://vault-api.usemoon.ai',
			securityWorker: async (token: string) => {
				return {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
			},
		} as ApiConfig);
		api.setSecurityData(token);
		const nonce: AccountResponse = await api
			.getNonce(wallet.name, {
				secure: true,
			})
			.then((res) => res.data);
		// return contract.data?.address || '';
		setNonce(nonce.data?.nonce || '');
		return nonce.data?.nonce || '';
	};

	const fetchWallets = async () => {
		const api = new Accounts({
			baseUrl: 'https://vault-api.usemoon.ai',
			securityWorker: async (token: string) => {
				return {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
			},
		} as ApiConfig);
		api.setSecurityData(token);
		const accounts: AccountResponse = await api
			.listAccounts({ secure: true })
			.then((res) => res.data);
		// turn accounts into walletts and set wallets
		const wallets: Wallet[] =
			accounts.data?.keys?.map((key) => {
				return {
					id: key,
					name: key,
					balance: 0,
				} as Wallet;
			}) || [];
		wallets.forEach(async (wallet) => {
			wallet.balance = await fetchBalance(wallet.id);
			wallet.nonce = await getNonce(wallet);
		});

		setWallets(wallets);
		setSelectedWallet(wallets[0]);
	};
	const createWallet = async (privateKey: string) => {
		const api = new Accounts({
			baseUrl: 'https://vault-api.usemoon.ai',
			securityWorker: async (token: string) => {
				return {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
			},
		} as ApiConfig);
		api.setSecurityData(token);

		const account: AccountResponse = await api
			.createAccount(
				{
					private_key: privateKey || '',
				},
				{
					secure: true,
				}
			)
			.then((res) => res.data);
		//set wallets
		const newWallets: Wallet[] = [
			...wallets,
			{
				id: account.data?.address || '',
				name: account.data?.address || '',
				balance: 0,
				nonce: '0',
			},
		];
		setWallets(newWallets);
		// return account.Data?.keys || [];
	};

	useEffect(() => {
		const profile = async (authToken: string) => {
			const profile = await fetch('https://vault-api.usemoon.ai/auth/profile', {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			}).then((res) => res.json());
			setUser(profile);
		};
		// Check if the user's authentication token is stored in a cookie
		const authToken = document.cookie
			.replace(/(?:(?:^|.*;\s*)authToken\s*\=\s*([^;]*).*$)|^.*$/, '$1')
			.trim();
		console.log('authToken');
		console.log(typeof authToken);
		if (authToken !== '') {
			// Fetch the user's data from the server using the authentication token

			setToken(authToken);
			setIsAuthenticated(true);
			profile(authToken);
		}
		console.log('isAuthenticated');
		console.log(isAuthenticated);
	}, []);

	// useEffect(() => {
	// 	if (isAuthenticated) fetchWallets();
	// }, [isAuthenticated, fetchWallets]);

	// useEffect(() => {
	// 	const initEthers = async () => {
	// 		// const provider = ethers.providers.getDefaultProvider('rinkeby');
	// 		// const signer = new ethers.Wallet('YOUR_PRIVATE_KEY', provider);
	// 		const provider = new ethers.JsonRpcProvider(
	// 			chain?.rpc || 'https://grpc.moon.social'
	// 		);
	// 		const signer = new MoonSigner(
	// 			selectedWallet?.name || '',
	// 			'https://vault-api.usemoon.ai',
	// 			provider
	// 		);
	// 		const moonSigner = signer.connect(provider);
	// 		// const provider = ethers.providers.getDefaultProvider('rinkeby');
	// 		// azureKeyVaultSigner = azureKeyVaultSigner.connect(provider);
	// 		setProvider(provider);
	// 		setSigner(signer);
	// 	};
	// 	initEthers();
	// }, [selectedWallet, chain]);
	return (
		<AuthContext.Provider
			value={{
				user,
				isAuthenticated,
				setIsAuthenticated,
				setToken,
				loading,
				token,
				refreshToken,
				login,
				register,
				logout,
				handleGithubLogin,
				handleGoogleLogin,
				handleFacebookLogin,
				handleTwitterLogin,
				handleDiscordLogin,
				handleLogin,
				handleRegister,
				refresh,
				forgotPassword,
				resetPassword,
				verifyEmail,
				updateProfile,
				updatePassword,
				updateEmail,
				deleteAccount,
				getTwoFactorAuthenticationCode,

				wallets,
				selectedWallet,
				chain,
				provider,
				signer,
				nonce,
				setNonce,

				setWallets,
				setSelectedWallet,
				setChain,
				setProvider,
				setSigner,

				fetchWallets,
				createWallet,
				deployContract,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
	// return {
	// 	user,
	// 	login,
	// 	logout,
	// 	isAuthenticated,
	// 	loading,
	// 	handleGithubLogin,
	// 	handleGoogleLogin,
	// 	handleTwitterLogin,
	// 	handleUsernamePasswordLogin,
	// 	handleLogin,
	// 	handleRegister,
	// 	profile,
	// 	token,
	// 	refreshToken,
	// };
};

export default useAuth;
