import {
	Button,
	Card,
	CardContent,
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemText,
	TextField,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { startRegistration } from '@simplewebauthn/browser';
import { useIDKit } from '@worldcoin/idkit';
import React, { useEffect, useState } from 'react';
import {
	DiscordLoginButton,
	GoogleLoginButton,
} from 'react-social-login-buttons';
import useAuth from '../../hooks/Wallet/useAuth';
// import { webcrypto } from 'crypto';
// import { webcrypto } from 'crypto';
// import crypto from 'crypto-browserify';
// import { webcrypto } from 'crypto-browserify';

const AuthContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh',
	backgroundColor: '#f5f5f5',
});

const AuthCard = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '32px',
	borderRadius: '8px',
	backgroundColor: '#fff',
	boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
});

const AuthTitle = styled('h1')({
	margin: '0',
	marginBottom: '16px',
	fontSize: '24px',
	fontWeight: 'bold',
	color: '#333',
});

const AuthForm = styled('form')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	marginTop: '16px',
});

const AuthInput = styled(TextField)({
	width: '100%',
	marginBottom: '16px',
});

const AuthButton = styled(Button)({
	width: '100%',
	marginBottom: '16px',
});

const AuthLoading = styled(CircularProgress)({
	color: '#fff',
});

const AuthSocialButtons = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	marginTop: '16px',
});
export const Register = () => {
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [publicKey, setPublicKey] = useState<any>({});
	const {
		loading,
		handleGithubLogin,
		handleGoogleLogin,
		handleTwitterLogin,
		handleDiscordLogin,
		handleRegister,
	} = useAuth();
	const webauthNRegister = () => {
		handleRegister(username);
	};
	const handleWorldStartRegister = async (username: string) => {
		try {
			const publicKey = await fetch('https://.ai/auth/webauthn/register', {
				method: 'POST',
				body: JSON.stringify({ username }),
				headers: { 'Content-Type': 'application/json' },
			}).then((res) => res.json());
			setPublicKey(publicKey);
		} catch (err) {
			console.error(err);
			alert('Failed to register with WebAuthn.');
		}
	};
	const handleWorldFinishRegister = async () => {
		const credential = await startRegistration(publicKey.options);
		await fetch('https://vault-api.usemoon.ai/auth/webauthn/register/verify', {
			method: 'POST',
			body: JSON.stringify({
				...credential,
			}),
			headers: { 'Content-Type': 'application/json' },
		});
		alert('Successfully registered with WebAuthn!');
	};

	const { open, setOpen } = useIDKit();
	const handleVerify = (response: any) => {
		console.log(response);
	};

	const onSuccess = async (response: any) => {
		console.log(response);
		const { challenge, key } = response;
		console.log('Challenge: ' + challenge);
		// TODO: stupid signing thing wont work, need https etc
		// take the challenge and sign it
		window.crypto.subtle
			.sign(
				{ name: 'ECDSA', hash: 'SHA-256' },
				response.nullifier_hash,
				challenge
			)
			.then((sig: any) => {
				console.log('Signature: ' + sig);
			});
		var ct = new CredentialsContainer();
		ct.store(challenge);
		// await fetch('https://vault-api.usemoon.ai/auth/webauthn/register/verify', {
		// 	method: 'POST',
		// 	body: JSON.stringify({
		// 		...credential,
		// 	}),
		// 	headers: { 'Content-Type': 'application/json' },
		// });
	};
	return (
		<AuthContainer>
			<AuthCard>
				<AuthTitle>Register</AuthTitle>
				<AuthForm>
					<AuthInput
						label="Username"
						variant="outlined"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					{/* <AuthInput
						label="Email"
						variant="outlined"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<AuthInput
						label="Password"
						variant="outlined"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/> */}
					<AuthButton
						variant="contained"
						color="primary"
						onClick={webauthNRegister}
						disabled={loading}
					>
						{loading ? <AuthLoading size={24} /> : 'Webauthn Register'}
					</AuthButton>
					{/* <div>
						<IDKitWidget
							app_id="app_60ed77246bac4082debe785ef5e1a240" // obtained from the Developer Portal
							action="verify" // this is your action name from the Developer Portal
							signal="user_value" // any arbitrary value the user is committing to, e.g. a vote
							handleVerify={handleVerify} // optional callback when the proof is received
							credential_types={[CredentialType.Orb, CredentialType.Phone]} // optional, defaults to ['orb']
							onSuccess={onSuccess}
						/>
					</div>
					<AuthButton
						variant="contained"
						color="primary"
						onClick={() => {
							// promptPasskey();
							handleWorldStartRegister(username);
							setOpen(true);
						}}
					>
						Verify with World ID
					</AuthButton> */}
				</AuthForm>
				<AuthSocialButtons>
					<GoogleLoginButton onClick={handleGoogleLogin} />
					<DiscordLoginButton onClick={handleDiscordLogin} />
					{/* <GithubLoginButton onClick={handleGithubLogin} /> */}
					{/* <TwitterLoginButton onClick={handleTwitterLogin} /> */}
				</AuthSocialButtons>
			</AuthCard>
		</AuthContainer>
	);
};

export const Login = () => {
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const {
		isAuthenticated,
		handleLogin,
		handleGithubLogin,
		handleGoogleLogin,
		handleTwitterLogin,
		handleDiscordLogin,
		loading,
	} = useAuth();
	const webauthnLogin = async () => {
		await handleLogin(username);
	};
	const { open, setOpen } = useIDKit();

	const getOptions: CredentialRequestOptions = {
		mediation: 'required',
		publicKey: {
			challenge: new Uint8Array([0, 1, 2, 3, 4, 5, 6, 7]),
			rpId: 'https://vault-api.usemoon.ai',
		},
	};

	function promptPasskey() {
		var promise = navigator.credentials.get(getOptions).catch((err) => {});
	}
	// useEffect(() => {
	// 	const urlParams = new URLSearchParams(window.location.search);
	// 	const responseType = urlParams.get('response_type');
	// 	const clientId = urlParams.get('client_id');
	// 	const redirectUri = urlParams.get('redirect_uri');
	// 	const scope = urlParams.get('scope');
	// 	const state = urlParams.get('state');

	// 	console.log(responseType, clientId, redirectUri, scope, state);
	// 	history.push(
	// 		`/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`
	// 	);
	// }, []);

	// const googleLogin = () => {
	// 	handleGoogleLogin();
	// };
	const handleVerify = (response: any) => {
		console.log(response);
	};
	const onSuccess = (response: any) => {
		console.log(response);
		webauthnLogin();
		// const { challenge, key } = response;
		// console.log('Challenge: ' + challenge);
		// // TODO: stupid signing thing wont work, need https etc
		// // take the challenge and sign it
		// crypto.subtle
		// 	.sign({ name: 'ECDSA', hash: 'SHA-256' }, key, challenge)
		// 	.then((sig: any) => {
		// 		console.log('Signature: ' + sig);
		// 	});
		// var ct = new CredentialsContainer();
		// ct.store(challenge);
		// // Post this somewhere
		// console.log('success', response);
	};
	return (
		<AuthContainer>
			<AuthCard>
				<AuthTitle>Login</AuthTitle>
				<AuthForm>
					<AuthInput
						label="Username"
						variant="outlined"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					{/* <AuthInput
						label="Email"
						variant="outlined"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<AuthInput
						label="Password"
						variant="outlined"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/> */}
					<AuthButton
						variant="contained"
						color="primary"
						onClick={webauthnLogin}
						disabled={loading}
					>
						{loading ? <AuthLoading size={24} /> : 'Webauthn Sign In'}
					</AuthButton>
					{/* <div>
						<IDKitWidget
							app_id="app_60ed77246bac4082debe785ef5e1a240" // obtained from the Developer Portal
							action="verify" // this is your action name from the Developer Portal
							signal="user_value" // any arbitrary value the user is committing to, e.g. a vote
							handleVerify={handleVerify} // optional callback when the proof is received
							credential_types={[CredentialType.Orb, CredentialType.Phone]} // optional, defaults to ['orb']
							onSuccess={onSuccess}
						/>
					</div>
					<AuthButton
						variant="contained"
						color="primary"
						onClick={() => {
							// promptPasskey();
							setOpen(true);
						}}
					>
						Verify with World ID
					</AuthButton>


						<button
							onClick={() => {
								promptPasskey();
								// setOpen(true);
							}}
						>
							Passkey / WorldID
						</button>					{/* <div> */}
					{/* </div> */}
				</AuthForm>
				<AuthSocialButtons>
					<GoogleLoginButton onClick={handleGoogleLogin} />
					<DiscordLoginButton onClick={handleDiscordLogin} />
					{/* <GithubLoginButton onClick={handleGithubLogin} /> */}
					{/* <TwitterLoginButton onClick={handleTwitterLogin} /> */}
				</AuthSocialButtons>
			</AuthCard>
		</AuthContainer>
	);
};

// interface AuthorizePageProps {}

// export const AuthorizePage = () => {
// 	const [username, setUsername] = useState('');
// 	const [password, setPassword] = useState('');
// 	const [loading, setLoading] = useState(false);
// 	const { isAuthenticated, token } = useAuth();
// 	const handleAuthorize = async () => {
// 		setLoading(true);
// 		// const searchParams = new URLSearchParams(window.location.search);
// 		//http://https://vault-api.usemoon.ai:3001/authorize?response_type=code&client_id=4e3a853ff1f01e72fb3bf208ba6c178a&redirect_url=undefined&scope=&state=c6b1368e-8894-4f3c-ab5e-23c39c1c66ec

// 		// const redirect_uri = searchParams.get('redirect_uri') || '';
// 		// const client_id = searchParams.get('client_id') || '';
// 		// const scope = searchParams.get('scope') || '';
// 		// const state = searchParams.get('state') || '';
// 		// console.log(searchParams.get('client_id'));
// 		// const searchParams = new URLSearchParams();
// 		// searchParams.append('response_type', 'code');
// 		// searchParams.append('client_id', client_id);
// 		// searchParams.append('redirect_uri', redirect_uri);
// 		// searchParams.append('scope', scope);
// 		// searchParams.append('state', state);
// 		const searchParams = new URLSearchParams(window.location.search);
// 		const client_id = searchParams.get('client_id');
// 		const redirect_uri = searchParams.get('redirect_uri');
// 		const scope = searchParams.get('scope');
// 		const state = searchParams.get('state');
// 		console.log(client_id, redirect_uri, scope, state);

// 		// const
// 		// const body = new URLSearchParams({
// 		// 	response_type: 'code',
// 		// 	client_id,
// 		// 	redirect_uri,
// 		// 	scope,
// 		// 	state,
// 		// });
// 		// console.log(body);
// 		const code = await fetch(
// 			'https://vault-api.usemoon.ai/auth/oauth/openai/authorize',
// 			{
// 				method: 'POST',
// 				headers: {
// 					Authorization: `Bearer ${token}`,
// 				},
// 				body: searchParams,
// 			}
// 		).then((res) => res.json());
// 		console.log(code);
// 		setLoading(false);
// 		window.location.href = `${code.redirect_uri}?code=${code.code}&state=${code.state}`;
// 	};

// 	return (
// 		<>
// 			{isAuthenticated ? (
// 				<AuthContainer>
// 					<Card>
// 						<CardContent>
// 							<Typography variant="h5" component="h2">
// 								Authorize Application
// 							</Typography>
// 							<Button
// 								variant="contained"
// 								color="primary"
// 								onClick={handleAuthorize}
// 								disabled={loading}
// 								fullWidth
// 							>
// 								{loading ? 'Loading...' : 'Authorize'}
// 							</Button>
// 						</CardContent>
// 					</Card>
// 				</AuthContainer>
// 			) : (
// 				<Login />
// 			)}
// 		</>
// 	);
// };

interface Props {
	useCookies: boolean;
	// isAuthenticated: boolean;
	// clientID: string;
	// redirectURI: string;
	// scope: string;
	// state: string;
	// token: string;
}

export const AuthorizePage: React.FC<Props> = ({ useCookies }) => {
	const [loading, setLoading] = useState(false);
	const [permissions, setPermissions] = useState<string[]>([]);
	const { isAuthenticated, token, user } = useAuth();
	useEffect(() => {
		if (!useCookies) {
			const url = new URLSearchParams(window.location.search);

			const redirectURI = url.get('redirect_uri') || '';
			const clientID = url.get('client_id') || '';
			const scope = url.get('scope') || '';
			const state = url.get('state') || '';
			console.log(url.get('client_id'));

			const searchParams = new URLSearchParams({
				response_type: 'code',
				client_id: clientID,
				redirect_uri: redirectURI,
				scope: scope,
				state: state,
			});

			document.cookie = `params=${JSON.stringify({
				response_type: 'code',
				client_id: clientID,
				redirect_uri: redirectURI,
				scope: scope,
				state: state,
			})}; path=/`;
		}
	}, []);

	const handleAuthorize = async (allow: boolean) => {
		let searchParams = new URLSearchParams(window.location.search);
		setLoading(true);
		if (useCookies) {
			const cookie =
				document.cookie
					.split('; ')
					.find((row) => row.startsWith('params'))
					?.split('=')[1] || '';

			const params = cookie ? JSON.parse(cookie) : null;
			searchParams = new URLSearchParams(params);
		} else {
			// const { clientID, redirectURI, scope, state } = new URLSearchParams(window.location.search);
			const url = new URLSearchParams(window.location.search);

			const redirectURI = url.get('redirect_uri') || '';
			const clientID = url.get('client_id') || '';
			const scope = url.get('scope') || '';
			const state = url.get('state') || '';
			console.log(url.get('client_id'));

			searchParams = new URLSearchParams({
				response_type: 'code',
				client_id: clientID,
				redirect_uri: redirectURI,
				scope: scope,
				state: state,
			});

			// 	document.cookie = `params=${JSON.stringify({
			// 		response_type: 'code',
			// 		client_id: clientID,
			// 		redirect_uri: redirectURI,
			// 		scope: scope,
			// 		state: state,
			// 	})}; path=/`;
		}

		const code = await fetch(
			'https://vault-api.usemoon.ai/auth/oauth/openai/authorize',
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: searchParams,
			}
		).then((res) => res.json());

		setLoading(false);
		if (allow) {
			window.location.href = `${code.redirect_uri}?code=${code.code}&state=${code.state}`;
		} else {
			window.location.href = searchParams.get('redirect_uri') || '';
		}
	};

	const handlePermissions = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = event.target;
		if (checked) {
			setPermissions([...permissions, value]);
		} else {
			setPermissions(permissions.filter((permission) => permission !== value));
		}
	};

	return (
		<>
			{isAuthenticated ? (
				<Card>
					<CardContent>
						<Typography variant="h5" component="h2">
							Authorize Application
						</Typography>
						<List>
							<ListItem>
								<ListItemText primary="Account Details" secondary="John Doe" />
							</ListItem>
							<Divider />
							{/* <ListItem>
								<ListItemText primary="Permissions" />
							</ListItem>
							<ListItem>
								<ListItemText primary="Permission 1" />
								<input
									type="checkbox"
									value="permission1"
									onChange={handlePermissions}
								/>
							</ListItem>
							<ListItem>
								<ListItemText primary="Permission 2" />
								<input
									type="checkbox"
									value="permission2"
									onChange={handlePermissions}
								/>
							</ListItem> */}
							<Divider />
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleAuthorize(true)}
								disabled={loading}
								fullWidth
							>
								{loading ? <CircularProgress size={24} /> : 'Allow'}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => handleAuthorize(false)}
								disabled={loading}
								fullWidth
							>
								{loading ? <CircularProgress size={24} /> : 'Deny'}
							</Button>
						</List>
					</CardContent>
				</Card>
			) : (
				<Login />
			)}
		</>
	);
};
