import { useEffect } from 'react';
import useAuth from '../../hooks/Wallet/useAuth';
import { AuthorizePage } from '../Auth/Webauthn';

const DiscordCallbackPage = () => {
	const { setIsAuthenticated, setToken } = useAuth();

	useEffect(() => {
		// fetch all url params and append to url as query params
		// const urlParams = new URLSearchParams(window.location.search);

		const url = `https://vault-api.usemoon.ai/auth/oauth/discord/callback${window.location.search}}`;
		// let url = 'https://vault-api.usemoon.ai/auth/oauth/google/callback';
		// urlParams.forEach((value, key) => {
		// 	url += `&${key}=${value}`;
		// });

		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				// Set JWT token cookie
				document.cookie = `authToken=${data.token}; path=/`;
				setToken(data.token);
				setIsAuthenticated(true);

				// Redirect to home page
				// history.push('/');
				// window.location.href = '/';
			})
			.catch((error) => {
				console.error(error);
				// Handle error
			});
	}, []);

	return <AuthorizePage useCookies={true} />;
};

export default DiscordCallbackPage;
