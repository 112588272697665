import { useState, useEffect } from 'react';

interface Profile {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	displayName: string;
	photoURL: string;
}

const useProfile = () => {
	const [profile, setProfile] = useState<Profile>({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		displayName: '',
		photoURL: '',
	});

	useEffect(() => {
		const fetchProfile = async () => {
			try {
				const response = await fetch('/api/profile').then((res) => res.json());
				setProfile(response.data);
			} catch (error) {
				setProfile({
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					displayName: '',
					photoURL: '',
				});
			}
		};
		fetchProfile();
	}, []);

	const updateProfile = async (data: Profile) => {
		try {
			const response = await fetch('/api/profile', {
				method: 'PUT',
				body: JSON.stringify(data),
			}).then((res) => res.json());
			setProfile(response.data);
		} catch (error) {
			setProfile({
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				displayName: '',
				photoURL: '',
			});
		}
	};

	return { profile, updateProfile };
};

export default useProfile;
