import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Nav from './components/Nav/Nav';
import { AuthProvider } from './hooks/Wallet/useAuth';
import { AuthorizePage, Login, Register } from './pages/Auth/Webauthn';
import DiscordCallbackPage from './pages/Callbacks/Discord';
import GoogleCallbackPage from './pages/Callbacks/Google';
import ChatPage from './pages/Chat/ChatPage';
import { CodeEditorPage } from './pages/Chat/SolidityCode';
import HomePage from './pages/Home';
import { OAuth2ClientCredentialsPage } from './pages/Oauth2/CredentialsPage';
import Profile from './pages/Profile/profile';
import WalletPage from './pages/Wallet/Wallet';

const theme = createTheme({});
function App() {
	return (
		<AuthProvider>
			<Router>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Nav />

					<Routes>
						{/* <Route path="/auth" element={<Auth />}></Route> */}
						<Route path="/" element={<HomePage />}></Route>

						{/* Login route */}
						<Route path="/login" element={<Login />}></Route>
						{/* sign up route */}
						<Route path="/register" element={<Register />}></Route>
						<Route path="/code" element={<CodeEditorPage />}></Route>
						{/* wallet route */}
						<Route path="/wallet" element={<WalletPage />}></Route>
						<Route
							path="/oauth2"
							element={<OAuth2ClientCredentialsPage />}
						></Route>
						<Route
							path="/authorize"
							element={<AuthorizePage useCookies={false} />}
						></Route>
						<Route path="/profile" element={<Profile />}></Route>
						<Route path="/chat" element={<ChatPage />}></Route>
						<Route
							path="/auth/oauth/google/callback"
							element={<GoogleCallbackPage />}
						></Route>
						<Route
							path="/auth/oauth/discord/callback"
							element={<DiscordCallbackPage />}
						></Route>

						<Route path="*" element={<h1>404</h1>}></Route>
					</Routes>
				</ThemeProvider>
			</Router>
		</AuthProvider>
	);
}

export default App;
