import React, { useEffect, useState } from 'react';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Link,
	Table,
	Modal,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useWallet } from '../../hooks/Wallet/useWallet';
import useAuth from '../../hooks/Wallet/useAuth';
import Box from '@mui/material/Box';

const WalletContainer = styled(Grid)({
	display: 'flex',
	flexDirection: 'row',
	height: '100vh',
});

const SidebarContainer = styled(Grid)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '300px',
	backgroundColor: '#f5f5f5',
});

const MainContainer = styled(Grid)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	flexGrow: 1,
});

const ProfileContainer = styled(Grid)({
	marginTop: '32px',
});

const ProfileCard = styled(Card)({
	maxWidth: '600px',
	margin: '0 auto',
});

const ProfileHeader = styled(CardHeader)({
	textAlign: 'center',
});

const ProfileContent = styled(CardContent)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

const ProfileAvatar = styled(Avatar)({
	width: '128px',
	height: '128px',
	marginBottom: '16px',
});

const ProfileTextField = styled(TextField)({
	marginBottom: '16px',
});

const ProfileButton = styled(Button)({
	marginTop: '16px',
});

const WalletAddress = styled(Typography)({
	marginBottom: '16px',
});

const WalletQRCode = styled(Avatar)({
	width: '128px',
	height: '128px',
	marginBottom: '16px',
});

const WalletBalance = styled(Typography)({
	marginBottom: '16px',
});

const WalletSelection = styled(TextField)({
	marginBottom: '16px',
});

const TransferContainer = styled(Grid)({
	marginTop: '32px',
});

const TransferCard = styled(Card)({
	maxWidth: '600px',
	margin: '0 auto',
});

const TransferHeader = styled(CardHeader)({
	textAlign: 'center',
});

const TransferContent = styled(CardContent)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

const TransferTextField = styled(TextField)({
	marginBottom: '16px',
});

const TransferButton = styled(Button)({
	marginTop: '16px',
});

const TransactionsContainer = styled(Grid)({
	marginTop: '32px',
});

const TransactionsCard = styled(Card)({
	maxWidth: '600px',
	margin: '0 auto',
});

const TransactionsHeader = styled(CardHeader)({
	textAlign: 'center',
});

const TransactionsContent = styled(CardContent)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

const TransactionsList = styled(Grid)({
	marginTop: '16px',
});
interface Transaction {
	to: string;
	contract: string;
	value: string;
	cost: string;
	nonce: string;
	from: string;
	gas: string;
	hash: string;
	blockHash: string;
	gasPrice: string;
	state: string;
}

const WalletPage = () => {
	// const [walletAddress, setWalletAddress] = useState('');
	// const [walletBalance, setWalletBalance] = useState('');
	// const [selectedWallet, setSelectedWallet] = useState('');
	// const [transferEth, setTransferEth] = useState('');
	const [transferErc721, setTransferErc721] = useState('');
	const [transferErc20, setTransferErc20] = useState('');
	const [signTx, setSignTx] = useState('');
	const [transferErc1155, setTransferErc1155] = useState('');
	const [transactions, setTransactions] = useState([]);
	// const { createWallet } = useWallet();
	const {
		createWallet,
		wallets,
		selectedWallet,
		token,
		isAuthenticated,
		nonce,
	} = useAuth();
	const [transactionsList, setTransactionsList] = useState<Transaction[]>([]);

	useEffect(() => {
		const web = () => {
			const socket = new WebSocket(
				'ws://https://vault-api.usemoon.ai:7000/v1/ws'
			);
			socket.onopen = () => {
				socket.send(
					JSON.stringify({
						name: `transaction/${selectedWallet?.name}/*`,
						type: 'subscribe',
						apiKey:
							'0x2daf86f49a7889edbbdb706beac7710e4540a24fe09888037b88203d8abb296c',
					})
				);
				socket.send(
					JSON.stringify({
						name: `transaction/*/${selectedWallet?.name}`,
						type: 'subscribe',
						apiKey:
							'0x2daf86f49a7889edbbdb706beac7710e4540a24fe09888037b88203d8abb296c',
					})
				);
			};
			socket.onmessage = (event) => {
				console.log(event);
				const data: Transaction = JSON.parse(event.data);
				if (data.from === selectedWallet?.name) {
					// // set transactionsList with data
					// setTransactionsList((transactionsList) => [
					// 	...transactionsList,
					// 	data,
					// ]);
					// append data to transactionsList
					setTransactionsList((transactionsList) => [
						...transactionsList,
						data,
					]);
				} else {
					if (Notification.permission === 'granted') {
						new Notification(
							`Transaction from ${data.from} with hash ${data.hash} and value ${data.value}`
						);
					} else if (Notification.permission !== 'denied') {
						Notification.requestPermission().then((permission) => {
							if (permission === 'granted') {
								new Notification(
									`Transaction from ${data.from} with hash ${data.hash} and value ${data.value}`
								);
							}
						});
					}
				}
				setModalData(data);
				setOpen(true);
			};
			// return () => {
			// 	socket.close();
			// };
		};
		if (isAuthenticated) {
			web();
		}
	}, [selectedWallet, isAuthenticated]);

	useEffect(() => {
		const fetchTransaction = async () => {
			console.log(selectedWallet);
			let queries = [];
			let variables: any = [];

			let numNonce = Number(nonce) || 0;
			for (let i = 0; i < numNonce - 5; i++) {
				const query = `
  transaction: transactionFromAccountWithNonce(account: $account, nonce: $nonce) {
    to
    contract
    value
    cost
    nonce
    from
    gas
    hash
    blockHash
    gasPrice
    state
  }
`;

				// const variable = {
				// // 	// Set the variables used in the GraphQL query
				// // 	account: selectedWallet?.name,
				// // 	nonce: i.toString(),
				// // };
				// let variable: any = {
				// 	account: selectedWallet?.name,
				// };
				// variable[`nonce${i}`] = i.toString();
				// console.log(variable);
				// const qur = query
				// 	.replaceAll('$nonce', `$nonce${i}`)
				// 	.replaceAll('FetchNonce', `FetchNonce${i}`);
				// const qur = query.replaceAll('$nonce', `"${i}"`).replaceAll('$account', `"${selectedWallet?.name}"`).replaceAll("account: ", `account${i}: `).replaceAll("nonce: ", `nonce${i}:`);
				const qur = query
					.replaceAll('account: $account', `account: "${selectedWallet?.name}"`)
					.replaceAll('nonce: $nonce', `nonce: "${i}"`)
					.replaceAll('transaction: ', `transaction${i}: `);
				// .replaceAll('$nonce', `"${i}"`)
				// .replaceAll('$account', `"${selectedWallet?.name}"`).replaceAll("account: ", `account${i}: $account${i}`).replaceAll("nonce: ", `nonce${i}: $nonce${i}`);
				queries.push(qur);

				// console.log(qur);
				// queries.push(qur);
				// variables.push(variable);
			}

			fetch('http://127.0.0.1:7000/v1/graphql', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					APIKey:
						'0x2daf86f49a7889edbbdb706beac7710e4540a24fe09888037b88203d8abb296c',
				},
				body: JSON.stringify({
					query: ` query { ${queries.join('\n')} }`,
				}),
			})
				.then((response) => response.json())
				.then((data: { data: any }) => {
					console.log(data);
					// Handle the data returned by the GraphQL query
					// data.
					Object.keys(data.data).forEach((key) => {
						const transaction = data.data[key];
						if (transaction) {
							setTransactionsList((transactionsList) => [
								...transactionsList,
								transaction,
							]);
						}
					});
				})
				.catch((error) => {
					// Handle the error
					console.log(error);
				});
		};
		fetchTransaction();
	}, [nonce]);

	// const handleWalletAddressChange = (event: any) => {
	// 	setWalletAddress(event.target.value);
	// };

	// const handleWalletBalanceChange = (event: any) => {
	// 	setWalletBalance(event.target.value);
	// };

	// const handleSelectedWalletChange = (event: any) => {
	// 	setSelectedWallet(event.target.value);
	// };

	const handleTransferEthChange = (event: any) => {
		// setTransferEth(event.target.value);
	};

	const handleTransferErc721Change = (event: any) => {
		setTransferErc721(event.target.value);
	};

	const handleTransferErc20Change = (event: any) => {
		setTransferErc20(event.target.value);
	};

	const handleSignTxChange = (event: any) => {
		setSignTx(event.target.value);
	};

	const handleTransferErc1155Change = (event: any) => {
		setTransferErc1155(event.target.value);
	};

	const handleTransactionsChange = (event: any) => {
		setTransactions(event.target.value);
	};

	const handleTransferEthSubmit = () => {
		// Handle transfer ETH functionality
	};

	const handleTransferErc721Submit = () => {
		// Handle transfer ERC721 functionality
	};

	const handleTransferErc20Submit = () => {
		// Handle transfer ERC20 functionality
	};

	const handleSignTxSubmit = () => {
		// Handle sign TX functionality
	};

	const handleTransferErc1155Submit = () => {
		// Handle transfer ERC1155 functionality
	};
	const [privateKey, setPrivateKey] = useState('');
	const handleCreateWallet = async () => {
		await createWallet(privateKey);
	};
	const handlePrivateKeyChange = (event: any) => {
		setPrivateKey(event.target.value);
	};

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const [transferValue, setTransferValue] = useState('');
	const [transferFrom, setTransferFrom] = useState('');
	const [transferTo, setTransferTo] = useState('');

	const handleTransferValueChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setTransferValue(event.target.value);
	};

	const handleTransferFromChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setTransferFrom(event.target.value as string);
	};

	const handleTransferToChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setTransferTo(event.target.value as string);
	};

	const handleTransferSubmit = async () => {
		// Handle transfer submit logic here
		// await transferEth(transferTo, transferValue);
	};

	const [open, setOpen] = useState(false);
	// const [modalData, setModalData] = useState('');
	const [modalData, setModalData] = useState<Transaction>();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [state, setState] = useState(false);
	useEffect(() => {}, []);

	return (
		<WalletContainer>
			<Modal open={open} onClose={handleClose}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography variant="h6">Transaction Details</Typography>
					<Typography>From: {modalData?.from}</Typography>
					<Typography>To: {modalData?.to}</Typography>
					<Typography>Value: {modalData?.value}</Typography>
					<Link
						href={`https://scan.moon.social/tx/${modalData?.hash}`}
						target="_blank"
						rel="noopener"
					>
						{modalData?.hash}
					</Link>
					<Button onClick={handleClose}>Close</Button>
				</Box>
			</Modal>
			<MainContainer>
				<TransferContainer>
					<TransferCard>
						{/* <TransferHeader title="Transfer" /> */}
						<TransferContent>
							<FormControl>
								<TextField
									id="transfer-value"
									label="Value"
									value={transferValue}
									onChange={handleTransferValueChange}
									variant="outlined"
								/>
								<TextField
									id="transfer-to"
									label="To"
									value={transferTo}
									onChange={handleTransferToChange}
									variant="outlined"
								/>
								<Button variant="contained" onClick={handleTransferSubmit}>
									Transfer
								</Button>
							</FormControl>
							{/* <TransferTextField
								id="transfer-eth"
								label="Transfer ETH"
								value={transferEth}
								onChange={handleTransferEthChange}
								variant="outlined"
							/>
							<TransferButton
								variant="contained"
								onClick={handleTransferEthSubmit}
							>
								Transfer ETH
							</TransferButton>
							<Divider />
							<TransferTextField
								id="transfer-erc721"
								label="Transfer ERC721"
								value={transferErc721}
								onChange={handleTransferErc721Change}
								variant="outlined"
							/>
							<TransferButton
								variant="contained"
								onClick={handleTransferErc721Submit}
							>
								Transfer ERC721
							</TransferButton>
							<Divider />
							<TransferTextField
								id="transfer-erc20"
								label="Transfer ERC20"
								value={transferErc20}
								onChange={handleTransferErc20Change}
								variant="outlined"
							/>
							<TransferButton
								variant="contained"
								onClick={handleTransferErc20Submit}
							>
								Transfer ERC20
							</TransferButton>
							<Divider />
							<TransferTextField
								id="sign-tx"
								label="Sign TX"
								value={signTx}
								onChange={handleSignTxChange}
								variant="outlined"
							/>
							<TransferButton variant="contained" onClick={handleSignTxSubmit}>
								Sign TX
							</TransferButton>
							<Divider />
							<TransferTextField
								id="transfer-erc1155"
								label="Transfer ERC1155"
								value={transferErc1155}
								onChange={handleTransferErc1155Change}
								variant="outlined"
							/>
							<Divider />
							<TransferButton
								variant="contained"
								onClick={handleTransferErc1155Submit}
							>
								Transfer ERC1155
							</TransferButton> */}
							<TransferTextField
								id="privatekey"
								label="privatekey"
								value={privateKey}
								onChange={handlePrivateKeyChange}
								variant="outlined"
							/>
							<TransferButton variant="contained" onClick={handleCreateWallet}>
								Create Wallet
							</TransferButton>
						</TransferContent>
					</TransferCard>
				</TransferContainer>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Hash</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{transactionsList
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((transaction) => (
									<TableRow key={transaction.hash}>
										<TableCell>
											<Link
												href={`https://scan.moon.social/tx/${transaction.hash}`}
												target="_blank"
												rel="noopener"
											>
												{transaction.hash}
											</Link>
										</TableCell>
										<TableCell>{transaction.value}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component="div"
						count={transactionsList.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</TableContainer>
			</MainContainer>
		</WalletContainer>
	);
};

export default WalletPage;
