import { useState, useEffect } from 'react';
import {
	Card,
	CardContent,
	Typography,
	Button,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
	Divider,
} from '@mui/material';
import { Login } from '../Auth/Webauthn';
import useAuth from '../../hooks/Wallet/useAuth';

interface ClientCredentials {
	id: number;
	name: string;
	redirectUris: string[];
	scopes: string[];
	clientId: string;
	clientSecret: string;
	createdAt: Date;
}

interface Props {}

export const OAuth2ClientCredentialsPage: React.FC<Props> = ({}) => {
	const [loading, setLoading] = useState(false);
	const [clientCredentials, setClientCredentials] = useState<
		ClientCredentials[]
	>([]);
	const [name, setName] = useState('');
	const [redirectUris, setRedirectUris] = useState<string[]>([]);
	const [scopes, setScopes] = useState<string[]>([]);
	const { token, isAuthenticated } = useAuth();

	useEffect(() => {
		const fetchClientCredentials = async () => {
			setLoading(true);

			const response = await fetch(
				'https://vault-api.usemoon.ai/auth/oauth2/client-credentials',
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const data = await response.json();

			setClientCredentials(data);
			setLoading(false);
		};

		fetchClientCredentials();
	}, [token]);

	const handleGenerateClientCredentials = async () => {
		setLoading(true);

		const response = await fetch(
			'https://vault-api.usemoon.ai/auth/oauth2/client-credentials',
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					name,
					redirectUris,
					scopes,
				}),
			}
		);
		const data = await response.json();

		setClientCredentials([
			...clientCredentials,
			{ ...data, createdAt: new Date() },
		]);
		setLoading(false);
	};

	const handleRevokeClientCredentials = async (id: number) => {
		setLoading(true);

		await fetch(
			`https://vault-api.usemoon.ai/auth/oauth2/client-credentials/${id}`,
			{
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		const updatedClientCredentials = clientCredentials.filter(
			(clientCredential) => clientCredential.id !== id
		);
		setClientCredentials(updatedClientCredentials);
		setLoading(false);
	};

	return (
		<>
			{isAuthenticated ? (
				<Card>
					<CardContent>
						<Typography variant="h5" component="h2">
							OAuth2 Client Credentials
						</Typography>
						<List>
							{clientCredentials.map((clientCredential) => (
								<ListItem key={clientCredential.id}>
									<ListItemText primary={`Name: ${clientCredential.name}`} />
									<ListItemText
										primary={`Client ID: ${clientCredential.clientId}`}
									/>
									<ListItemText
										primary={`Client Secret: ${clientCredential.clientSecret}`}
									/>
									<ListItemText
										primary={`Created At: ${clientCredential.createdAt}`}
									/>
									<Button
										variant="contained"
										color="secondary"
										onClick={() =>
											handleRevokeClientCredentials(clientCredential.id)
										}
									>
										Revoke
									</Button>
								</ListItem>
							))}
							<Divider />
							<Typography variant="h6" component="h3">
								Generate Client Credentials
							</Typography>
							<input
								type="text"
								placeholder="Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Redirect URIs (comma-separated)"
								value={redirectUris.join(',')}
								onChange={(e) => setRedirectUris(e.target.value.split(','))}
							/>
							<input
								type="text"
								placeholder="Scopes (comma-separated)"
								value={scopes.join(',')}
								onChange={(e) => setScopes(e.target.value.split(','))}
							/>
							<Button
								variant="contained"
								color="primary"
								onClick={handleGenerateClientCredentials}
								disabled={loading}
								fullWidth
							>
								{loading ? (
									<CircularProgress size={24} />
								) : (
									'Generate Client Credentials'
								)}
							</Button>
						</List>
					</CardContent>
				</Card>
			) : (
				<Login />
			)}
		</>
	);
};
