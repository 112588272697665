import {
	AppBar,
	FormControl,
	IconButton,
	Select,
	Toolbar,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const NavContainer = styled(AppBar)({
	backgroundColor: '#fff',
	boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
});

const NavToolbar = styled(Toolbar)({
	display: 'flex',
	justifyContent: 'space-between',
});

const NavTitle = styled(Typography)({
	fontWeight: 'bold',
	color: '#333',
});

const NavAccountButton = styled(IconButton)({
	marginLeft: '16px',
});

const NavWalletButton = styled(IconButton)({
	marginLeft: '16px',
});

const NavChainButton = styled(IconButton)({
	marginLeft: '16px',
});

const NavBalance = styled(Typography)({
	fontWeight: 'bold',
	color: '#333',
	marginLeft: '8px',
});

const NavFormControl = styled(FormControl)({
	minWidth: '120px',
	marginLeft: '16px',
});

const NavSelect = styled(Select)({
	'& .MuiSelect-select': {
		paddingLeft: '24px',
	},
});

const Nav = () => {
	// const [anchorEl, setAnchorEl] = useState(null);
	// const [chainAnchorEl, setChainAnchorEl] = useState(null);

	// const {
	// 	isAuthenticated,
	// 	user,
	// 	logout,
	// 	wallets,
	// 	setSelectedWallet,
	// 	selectedWallet,
	// 	setChain,
	// 	chain,
	// 	fetchWallets,
	// 	setProvider,
	// 	setSigner,
	// } = useAuth();
	// // const {
	// // 	wallets,
	// // 	selectedWallet,
	// // 	setSelectedWallet,
	// // 	chain,
	// // 	setChain,
	// // 	signer,
	// // 	provider,
	// // } = useWallet();
	// useEffect(() => {
	// 	if (isAuthenticated) fetchWallets();
	// }, [user]);

	// useEffect(() => {
	// 	const initEthers = async () => {
	// 		// const provider = ethers.providers.getDefaultProvider('rinkeby');
	// 		// const signer = new ethers.Wallet('YOUR_PRIVATE_KEY', provider);
	// 		const provider = new ethers.JsonRpcProvider(
	// 			chain?.rpc || 'https://grpc.moon.social'
	// 		);
	// 		const signer = new MoonSigner(
	// 			selectedWallet?.name || '',
	// 			'https://vault-api.usemoon.ai',
	// 			provider
	// 		);
	// 		const moonSigner = signer.connect(provider);
	// 		// const provider = ethers.providers.getDefaultProvider('rinkeby');
	// 		// azureKeyVaultSigner = azureKeyVaultSigner.connect(provider);
	// 		setProvider(provider);
	// 		setSigner(moonSigner);
	// 	};
	// 	initEthers();
	// }, [selectedWallet, chain]);
	// const handleLogout = () => {
	// 	logout();
	// };
	// const handleAccountClick = (event: any) => {
	// 	setAnchorEl(event.currentTarget);
	// };

	// const handleAccountClose = () => {
	// 	setAnchorEl(null);
	// };

	// const handleWalletChange = (event: any) => {
	// 	const wallet = wallets.find((w) => w.name === event.target.value);
	// 	console.log(wallet);
	// 	setSelectedWallet(wallet as Wallet);
	// 	// setSelectedWallet(event.target.value);
	// };

	// const handleChainClick = (event: any) => {
	// 	setChainAnchorEl(event.currentTarget);
	// };

	// const handleChainClose = () => {
	// 	setChainAnchorEl(null);
	// };

	// const handleChainChange = (event: any) => {
	// 	// setSelectedChain(event.target.value);
	// 	if (!event.target.value) return;
	// 	const chain = chains.find((c) => c.name === event.target.value);
	// 	console.log(chain);
	// 	setChain(chain as Chain);
	// 	// setChain(chains.find((c) => c.name === event.target.value)
	// 	// setChain(event.target.value);
	// };

	return (
		<NavContainer position="static">
			<NavToolbar>
				<NavTitle>useMoon</NavTitle>
			</NavToolbar>
		</NavContainer>
	);
};

export default Nav;
