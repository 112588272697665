import React, { useState } from 'react';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	IconButton,
	TextField,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import useProfile from '../../hooks/Wallet/useProfile';
import useAuth from '../../hooks/Wallet/useAuth';

const ProfileContainer = styled(Grid)({
	marginTop: '32px',
});

const ProfileCard = styled(Card)({
	maxWidth: '600px',
	margin: '0 auto',
});

const ProfileHeader = styled(CardHeader)({
	textAlign: 'center',
});

const ProfileContent = styled(CardContent)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

const ProfileAvatar = styled(Avatar)({
	width: '128px',
	height: '128px',
	marginBottom: '16px',
});

const ProfileTextField = styled(TextField)({
	marginBottom: '16px',
});

const ProfileButton = styled(Button)({
	marginTop: '16px',
});

const Profile = () => {
	const { user, isAuthenticated } = useAuth();
	const { updateProfile } = useProfile();
	const [isEditing, setIsEditing] = useState(false);
	const [formData, setFormData] = useState({
		firstName: user?.firstName || '',
		lastName: user?.lastName || '',
		email: user?.email || '',
		displayName: user?.displayName || '',
		photoURL: user?.photoURL || '',
	});

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleSaveClick = () => {
		// updateProfile(formData);
		setIsEditing(false);
	};

	const handleInputChange = (event: any) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value,
		});
	};

	return (
		<ProfileContainer container>
			<Grid item xs={12} sm={8} md={6}>
				<ProfileCard>
					<ProfileHeader
						avatar={
							user?.photoURL ? (
								<ProfileAvatar src={user.photoURL} />
							) : (
								<ProfileAvatar />
							)
						}
						title={`${
							user?.displayName ? user.displayName : 'No Display Name'
						}`}
						subheader={`${user?.email ? user.email : 'No Email'}`}
						action={
							isEditing ? (
								<IconButton onClick={handleSaveClick}>
									<SaveIcon />
								</IconButton>
							) : (
								<IconButton onClick={handleEditClick}>
									<EditIcon />
								</IconButton>
							)
						}
					/>
					<Divider />
					<ProfileContent>
						<ProfileTextField
							label="First Name"
							name="firstName"
							value={formData.firstName}
							onChange={handleInputChange}
							disabled={!isEditing}
							fullWidth
						/>
						<ProfileTextField
							label="Last Name"
							name="lastName"
							value={formData.lastName}
							onChange={handleInputChange}
							disabled={!isEditing}
							fullWidth
						/>
						<ProfileTextField
							label="Email"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							disabled={!isEditing}
							fullWidth
						/>
						<ProfileButton variant="contained" color="primary">
							Link Social OAuth
						</ProfileButton>
						<ProfileButton variant="contained" color="primary">
							Generate API Credentials
						</ProfileButton>
						<ProfileButton variant="contained" color="primary">
							Export Data
						</ProfileButton>
					</ProfileContent>
				</ProfileCard>
			</Grid>
		</ProfileContainer>
	);
};

export default Profile;
