import { MoonSDK } from '@moonup/moon-sdk';
import {
	AUTH,
	MOON_SESSION_KEY,
	MOON_SUPPORTED_NETWORKS,
	Storage,
} from '@moonup/moon-types';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';

interface MoonSDKHook {
	moon: MoonSDK | null;
	initialize: () => Promise<void>;
	disconnect: () => Promise<void>;
	// signTransaction: (transaction: TransactionResponse) => Promise<Transaction>;
	// Add other methods as needed
}

export function useMoonSDK(): MoonSDKHook {
	const [moon, setMoon] = useState<MoonSDK | null>(null);

	const initialize = async () => {
		console.log(MOON_SUPPORTED_NETWORKS);
		const moonInstance = new MoonSDK({
			Storage: {
				key: MOON_SESSION_KEY,
				type: Storage.LOCAL,
			},
			Auth: {
				AuthType: AUTH.JWT,
			},
		});
		await moonInstance.connect();
		setMoon(moonInstance);
	};

	const disconnect = async () => {
		if (moon) {
			await moon.disconnect();
			setMoon(null);
		}
	};

	// const signTransaction = async (transaction: TransactionResponse) => {
	// 	if (moon) {
	// 		return moon.SignTransaction(transaction);
	// 	}
	// 	throw new Error('Moon SDK is not initialized');
	// };

	// Add other methods as needed

	useEffect(() => {
		initialize();
		return () => {
			disconnect();
		};
	}, []);

	return {
		moon,
		initialize,
		disconnect,
		// Add other methods as needed
	};
}

const HomePage = () => {
	const { moon } = useMoonSDK();
	useEffect(() => {
		console.log('moon', moon);
	}, []);
	// const accountResponse = useMoonAccounts();
	// // const [GetAddress, setAddress] = useState<string>('');
	// // useEffect(() => {
	// // 	console.log('signer', signer);
	// // 	console.log('provider', provider);
	// // 	const getAddress = async () => {
	// // 		const address = await signer?.getAddress();
	// // 		setAddress(address || '');
	// // 		console.log('address', address);
	// // 	};
	// // 	getAddress();
	// // }, [signer, provider]);
	// const [address, setAddress] = useState('');
	// const balance = useGetBalance(address);
	// const handleChange = (event: SelectChangeEvent) => {
	// 	setAddress(event.target.value as string);
	// 	const kek = signer?.connect(provider);
	// 	console.log('kek', kek);
	// 	kek?.getNonce(address).then((nonce) => {
	// 		console.log('nonce', nonce);
	// 	});
	// };
	// const { signer, provider } = useEthers(address);
	return <Box sx={{ minWidth: 120 }}></Box>;
};

export default HomePage;
