import { useState } from 'react';
import axios from 'axios';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { TextField, Button, Typography } from '@mui/material';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import useAuth, { Wallet } from '../../hooks/Wallet/useAuth';
// import { TextField, Button, Typography } from '@mui';

interface Contract {
	prompt: string;
	documentation: string;
	abi: any[];
	code: string;
	bytecode: string;
	// sqlQuery?: string;
	// // result?: string[];
	// result?: any;
	// query?: any;
	// variables?: any;

	// results?: any;
	// error?: string;
	// success?: boolean;
	// abi?: any;
	// bytecode?: any;
}

export const CodeEditorPage = () => {
	const [code, setCode] = useState('');
	const [input, setInput] = useState('');
	const [contract, setContract] = useState<Contract | null>(null);
	const [name, setName] = useState('');
	const { token, deployContract, selectedWallet } = useAuth();

	const handleInspect = () => {
		if (contract) {
			alert(JSON.stringify(contract.abi, null, 2));
		}
	};
	const compile = async (
		wallet: Wallet,
		source: string,
		contractName: string
	) => {
		const response = await fetch('https://vault-api.usemoon.ai/chat/code', {
			method: 'POST',
			body: JSON.stringify({
				query: source,
				name: contractName,
			}),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => res.json());
		console.log(response);
		const contract: Contract = {
			prompt: source,
			code: source,
			documentation: '',
			abi: response.abi,
			bytecode: response.bytecode,
		};
		console.log(contract);
		setContract(contract);
	};

	const handleFetch = async () => {
		const response = await fetch('https://vault-api.usemoon.ai/chat/code', {
			method: 'POST',
			body: JSON.stringify({
				query: input,
				name: name,
			}),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}).then((res) => res.json());
		console.log(response);
		// setCode(response.data);
		const contract: Contract = {
			prompt: response.prompt,
			documentation: response.audit[0],
			abi: response.abi,
			code: response.result.data.content
				.match(/^```(?:solidity)?\n([\s\S]*?)\n```$/m)[1]
				.replace(/\\n/g, '\n'),
			bytecode: response.bytecode,
		};
		console.log(contract);
		setContract(contract);
	};
	const handleDeployContract = async () => {
		if (!selectedWallet) {
			return;
		}
		const // Deploy the contract
			response = await deployContract(
				selectedWallet,
				JSON.stringify(contract?.abi),
				contract?.bytecode,
				'',
				'1337'
			);
		console.log(response);
	};

	return (
		<div>
			<Button onClick={handleFetch}>Fetch Code</Button>
			<SyntaxHighlighter language="solidity" style={darcula}>
				{code}
			</SyntaxHighlighter>
			<TextField
				label="Input"
				multiline
				rows={10}
				variant="outlined"
				value={input}
				onChange={(e) => setInput(e.target.value)}
			/>
			<TextField
				label="name"
				multiline
				rows={1}
				variant="outlined"
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>

			<Button onClick={handleDeployContract}>Deploy</Button>
			{contract && (
				<div>
					<Typography variant="h6">Smart contract:</Typography>
					<SyntaxHighlighter language="solidity" style={darcula}>
						{contract?.code}
					</SyntaxHighlighter>
					<SyntaxHighlighter language="json" style={darcula}>
						{JSON.stringify(contract?.abi)}
					</SyntaxHighlighter>
					<Button onClick={handleInspect}>Inspect ABI</Button>
				</div>
			)}
		</div>
	);
};
