/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AccountInputBody, AccountResponse } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Accounts<
	SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @tags Account
	 * @name ListAccounts
	 * @summary List all accounts
	 * @request GET:/accounts
	 */
	listAccounts = (params: RequestParams = {}) =>
		this.request<AccountResponse, any>({
			path: `/accounts`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name CreateAccount
	 * @summary Create account
	 * @request POST:/accounts
	 */
	createAccount = (data: AccountInputBody, params: RequestParams = {}) =>
		this.request<AccountResponse, any>({
			path: `/accounts`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name GetAccount
	 * @summary Get account by name
	 * @request GET:/accounts/{name}
	 */
	getAccount = (name: string, params: RequestParams = {}) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name DeleteAccount
	 * @summary Delete account
	 * @request POST:/accounts/{name}
	 */
	deleteAccount = (name: string, params: RequestParams = {}) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}`,
			method: 'POST',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name GetBalance
	 * @request GET:/accounts/{name}/balance
	 */
	getBalance = (name: string, params: RequestParams = {}) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}/balance`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name TransferEth
	 * @summary Transfer ETH
	 * @request POST:/accounts/{name}/transfer
	 */
	transferEth = (
		name: string,
		data: AccountInputBody,
		params: RequestParams = {}
	) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}/transfer`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name GetNonce
	 * @summary Get nonce
	 * @request POST:/accounts/{name}/nonce
	 */
	getNonce = (name: string, params: RequestParams = {}) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}/nonce`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name SignTx
	 * @summary Sign transaction
	 * @request POST:/accounts/{name}/sign-tx
	 */
	signTx = (name: string, data: AccountInputBody, params: RequestParams = {}) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}/sign-tx`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name BroadcastTx
	 * @summary Broadcast transaction
	 * @request POST:/accounts/{name}/broadcast-tx
	 */
	broadcastTx = (
		name: string,
		data: AccountInputBody,
		params: RequestParams = {}
	) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}/broadcast-tx`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name SignMessage
	 * @summary Sign Data
	 * @request POST:/accounts/{name}/sign-message
	 */
	signMessage = (
		name: string,
		data: AccountInputBody,
		params: RequestParams = {}
	) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}/sign-message`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Account
	 * @name DeployContract
	 * @summary Deploy contract
	 * @request POST:/accounts/{name}/deploy-contract
	 */
	deployContract = (
		name: string,
		data: AccountInputBody,
		params: RequestParams = {}
	) =>
		this.request<AccountResponse, any>({
			path: `/accounts/${name}/deploy-contract`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
}
